angular.module('app').filter('tripReasonMode', function () {
    return function (tripReasonMode) {
        var text;

        if (_.isUndefined(tripReasonMode)) return text;

        switch (tripReasonMode) {

            case "OPTIONAL":
                text = "Optional";
                break;
            case "REQUIRED":
                text = "Required";
                break;
            case "NOT_REQUIRED":
                text = "Not required";
                break;
            default:
                text = "n/a";
        }

        return text;

    };
});